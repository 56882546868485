.activitiesAdminHeader {
  padding: 30px;
  border-bottom: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  position: relative;

  .siteLogo {
    width: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.adminTab {
  border-right: 1px solid var(--light-grey);
  background-color: var(--light-2-grey);
  height: 100vh;
}
