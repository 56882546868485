.quotationHeader {
  border-bottom: 1px solid var(--light-grey);
  .quotationCompanyHeader {
    border-bottom: 1px solid var(--light-grey);
    color: var(--grey);
    padding: 30px 0px;
    .quotationHeaderLogo {
      width: 200px;
    }
    .quotationHeaderDetails {
      text-align: end;
      font-size: var(--fs-5);
      font-weight: 600;
    }
  }

  .quotationSubHeader {
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 18%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../../assets/images/headerBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    // text-align: center;
    // background-color: rgb(239, 239, 239);
    padding: 50px 0px;
  }
}
