.quotationFooter {
  margin-top: 80px;
  border-top: 1px solid var(--light-grey);
  border-bottom: 1px solid var(--light-grey);
  .aboutUs {
    font-size: var(--fs-4);
  }
  .certificate {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    .certificateIcon {
      height: 40px;
      margin-right: 30px;
    }
  }
  .dealsIn {
    display: flex;
    align-items: center;
    margin: 30px 0px;
    .moduleItem {
      background-color: var(--light-grey);
      padding: 6px 16px;
      margin: 0px 6px;
      border-radius: 8px;
      font-size: var(--fs-4);
    }
  }
  .contactSupport {
    margin: 30px 0px;
    // padding: 16px 20px;
    // background-color: var(--secondary);
    border-radius: 8px;
  }
  .bankDetails {
    font-size: var(--fs-4);
    .bankDetailsItem {
      .bankLogo {
        height: 20px;
      }
      //   background-color: var(--white);
      margin-top: 10px;
      padding: 10px 0px;
      border-top: 1px solid var(--light-grey);
      border-bottom: 1px solid var(--light-grey);
    }
  }
}
