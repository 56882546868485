@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap");

:root {
  --primary: #102e67;
  --primary-light: rgba(44, 0, 148, 0.13);
  --secondary: rgb(255, 102, 0);
  --secondary-light: #f29e0e24;
  --grey: #4d4d4d;
  --light-grey: #dedede;
  --light-2-grey: #f9f9f9;
  --white: #ffffff;

  // fonts
  --fs-6: 10px;
  --fs-5: 12px;
  --fs-4: 14px;
  --fs-3: 16px;
  --fs-2: 18px;
  --fs-1: 20px;
}

@font-face {
  font-family: "arabiFont";
  src: url("../assets/fonts/Kahlil.ttf");
}

body {
  font-family: "Montserrat", sans-serif;

  #gt_float_wrapper {
    top: 100px !important;
  }

  label.required {
    &::after {
      color: red;
      content: " *";
    }
  }

  .spinner-grow-sm {
    width: 5px !important;
    height: 5px !important;
  }

  .passenger-btn {
    position: relative;
    .btn {
      border: 1px solid var(--light-grey);
      padding-top: 9px;
      padding-bottom: 11px;
      border-radius: 5px;
      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }
    .flight-passenger-info {
      font-size: var(--sm-font);
    }

    .input-icon {
      color: var(--primary);
    }

    .form-popup {
      width: 300px;
      color: #313131;
      background-color: var(--white);
      // display: none;
      position: absolute;
      top: 50px;
      right: 0;
      border: 2px solid var(--light-2-grey);
      z-index: 9;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      @media (max-width: 370px) {
        right: -25px;
      }

      // .submit-passenger-class {
      //   margin-top: 20px;
      //   border-radius: 0px;
      //   color: var(--white);
      //   background-color: var(--primary);
      // }
      // .cancel {
      //   margin-top: 20px;
      //   border-radius: 0px;
      //   color: var(--primary);
      //   border: 1px solid var(--primary);
      // }

      .row {
        padding: 0px;
      }
    }
  }

  a {
    text-decoration: none;
    color: var(--grey);
    transition: ease 0.3s;

    &:hover {
      color: var(--secondary);
      font-weight: 600;
    }
  }

  // callLogsFloatingButton

  #callLogsFloatingButton {
    position: fixed;
    z-index: 9999;
    bottom: 20px;
    left: 20px;
    .float-button {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      color: white;
      font-size: 20px;
      width: 70px;
      height: 70px;
    }
    .callsContent {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      // border: 1px solid rgb(110, 110, 110);
      margin-bottom: 10px;
      width: 350px;
      max-width: 100%;
      height: 560px;
      border-radius: 12px;
      overflow-x: hidden;
      overflow-y: auto;
      @media (max-width: 400px) {
        width: 320px;
      }
      .fa-arrow-down {
        transform: rotate(45deg);
        font-size: 12px;
      }
      .fa-arrow-up {
        transform: rotate(45deg);
        font-size: 12px;
      }
      .callContentHeader {
        background-color: var(--light-grey);
      }

      .rotateLoading {
        transform: rotate(360deg);
        transition-duration: 1.5s;
        transition-delay: now;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }
    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(124, 124, 124);
      border-radius: 10px;
    }
  }

  #screen {
    min-height: 100vh;
    // padding: 60px 0px;
  }

  .small {
    font-size: 0.8rem;
  }

  p {
    margin-bottom: 0;
  }

  .border-primary {
    border: 1px solid var(--primary) !important;
  }
  .bg-primary {
    background-color: var(--primary) !important;
  }
  .bg-secondary {
    background-color: var(--secondary) !important;
  }
  .text-primary {
    color: var(--primary) !important;
  }
  .text-secondary {
    color: var(--secondary) !important;
  }

  .page-float-background {
    .bg-image {
      background-image: linear-gradient(
          45deg,
          var(--primary),
          rgba(0, 40, 116, 0)
        ),
        url("https://images.unsplash.com/photo-1613914011280-fc80bd159816?q=80&w=2131&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
      height: 200px;
      background-repeat: no-repeat;
      background-position: 90% 55%;
      background-size: cover;
    }
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .headerImage {
    background-image: linear-gradient(
        45deg,
        var(--white),
        rgba(255, 255, 255, 0)
      ),
      url("../../src/assets/images//headerBackground.png");
    height: 130px;
    background-repeat: no-repeat;
    background-position: 90% 55%;
    background-size: cover;
    display: flex;
    align-items: center;
    h3 {
      color: var(--primary);
      margin: 0;
      font-weight: 600;
    }
    @media (max-width: 991.98px) {
      height: 80px;
    }
  }

  .offcanvas {
    height: auto !important;
  }
  .offcanvas-top {
    height: 370px !important;
  }

  .offcanvas-start {
    width: 330px !important;
  }
  .offcanvas-body {
    padding: 0;
  }

  .dropdown-menu {
    font-size: var(--fs-4);
    width: 240px;
    top: 20px !important;
    left: -138px !important;
  }

  #cartPage-pk {
    .sticky-top {
      top: 120px;
      z-index: 0;
    }
  }

  #pk-activity-quotation-preview {
    // margin: 50px auto;
    width: auto;
    // padding: 20px;
    border: 1px solid var(--light-grey);

    table {
      width: auto;
      border-collapse: collapse;
      table-layout: auto; /* Allow columns to adjust based on content */
      @media (max-width: 991.98px) {
        width: max-content;
      }
    }

    th {
      background-color: #f2f2f2;
    }
    td {
      font-size: var(--fs-4);
      padding-bottom: 0px;
      padding-top: 4px;
      padding: 10px 16px;
      width: max-content;
    }
  }

  #pk-quotation-Offcanvas {
    width: 80% !important;
    @media (max-width: 767.98px) {
      width: 100%;
    }
    ul {
      li {
        padding: 0px;
        border-left: none;
      }
    }
  }

  .offcanvas-end {
    width: 340px !important;

    .offcanvas-header {
      border-bottom: 1px solid var(--light-grey);
    }

    .offcanvas-body {
      // background-color: rgba(242, 242, 242, 0.535);
      // padding: 16px;
    }

    #menu-dropdown {
      background-color: var(--white);
      // padding: 0px 10px;
      // border-radius: 0px 0px 5px 5px;
      // box-shadow: rgba(50, 50, 93, 0.2) 0px 2px 5px -1px,
      //   rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
      // li {
      //   margin-top: 2px;
      // }
    }

    ul {
      list-style: none;
      padding: 0;
      li {
        padding: 16px;
        border-bottom: 1px solid rgba(236, 236, 236, 0.837);
        // margin-top: 10px;
        // border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.629);
        font-size: var(--fs-4);
        border-left: 4px solid var(--white);
        &:hover {
          background-color: rgba(236, 236, 236, 0.688);
          cursor: pointer;
          border-left: 4px solid var(--primary);
        }
        img {
          width: 24px;
          margin-right: 10px;
        }
      }
      .li-active {
        background-color: rgba(236, 236, 236, 0.688);
        box-shadow: rgba(50, 50, 93, 0.2) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
        // border-radius: 5px 5px 0px 0px;
        border-left: 4px solid var(--primary);
      }
    }
  }
}

@media print {
  body {
    font-size: 12pt;
  }
  .page-break {
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 16px;
}

// front page logo marquee --------------
.marquee {
  overflow: hidden;
}

.marquee-content {
  display: flex;
  align-items: center;
  animation: scrolling 20s linear infinite;
}

.marquee-item {
  flex: 0 0 10vw;
  margin: 0 1vw;
  @media (max-width: 991.98px) {
    flex: 0 0 22vw;
  }
  @media (max-width: 767.98px) {
    flex: 0 0 25vw;
  }
}

.marquee-item img {
  display: block;
  width: 100%;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}
// -------------------

.swiper {
  width: 100%;
  height: 300px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 6px solid var(--light-grey);
}

.swiper-slide {
  width: 60%;
}
