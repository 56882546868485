.mainNavbar {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 15px;
  background-color: var(--white);
  padding: 12px 0px;
  .logo {
    width: 200px;
  }

  .headerProfilePopup {
    background-color: var(--white);
    border: 1px solid var(--light-grey);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
    padding: 20px;
    width: 240px;
    .links {
      color: var(--grey);
      font-size: var(--fs-4);
    }
  }

  button {
    @media (max-width: 767.98px) {
      font-size: var(--fs-5);
    }
  }
}
