.timeline {
    position: relative;
    margin-bottom: 20px;
}

.line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.marker {
    font-size: 12px;
    color: #666;
}

.bars {
    position: relative;
    height: auto;
}

.line-container {
    position: relative;
    height: 30px;
    margin-bottom: 5px;
}

.season-bar {
    position: absolute;
    height: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}


