.packageItem {
  background-color: var(--white);
  margin-bottom: 20px;
  img {
    border-radius: 16px;
    height: 200px;
    width: 100%;
    object-fit: cover;
    padding: 10px;
  }
  // border: 1px solid var(--light-grey);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 6px;
  .content {
    position: relative;
    padding: 10px 16px 10px 16px;
    min-height: 150px;
    p {
      font-size: var(--fs-5);
    }
  }

  .days {
    position: absolute;
    top: -20px;
    right: 0px;
    background-color: var(--white);
    border-radius: 20px;
    padding: 4px 12px;
    font-weight: 700;
    font-size: var(--fs-6);
  }
}
