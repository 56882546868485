.singleTour {
  margin-bottom: 20px;

  .singleTourListItem {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid var(--light-grey);
    // padding: 16px;
    border-radius: 6px;

    img {
      aspect-ratio: 16/9;
      width: 100%;
      border-radius: 8px;
    }

    .tourDetails {
      padding: 20px 20px 20px 0px;
      .tourShortDescription {
        font-size: var(--fs-4);
        color: var(--grey);
      }
      margin: 5px 0;
    }
    .tourBtns {
      // padding: 20px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .TourOptionCollapseBox {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--white);
    margin: 0px 10px;
    .tourOptionHeader {
      h6 {
        margin-bottom: 0;
      }
      border: 1px solid var(--light-grey);
      border-radius: 6px 6px 0px 0px;
      border-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background-color: var(--light-2-grey);
    }
  }
}
