.TourOptionItem {
  padding: 10px;
  //   border: 1px solid var(--light-grey);
  border-radius: 5px;
  margin: 5px 0px;

  .tourOptionWeek {
    display: flex;
    .tourOptionweekDays {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin: 4px;
      border-radius: 20px;
    }
    .tourOptionweekDaysActive {
      background-color: var(--primary);
      color: var(--white);
    }
    .tourOptionweekDaysNotActive {
      background-color: var(--light-grey);
      color: var(--grey);
    }
  }
}
