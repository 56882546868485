.spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
}
.backgroundOverlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
