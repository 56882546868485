@keyframes ldio-e63ka05htst-1 {
  0% {
    top: 36px;
    height: 128px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes ldio-e63ka05htst-2 {
  0% {
    top: 41.99999999999999px;
    height: 116.00000000000001px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes ldio-e63ka05htst-3 {
  0% {
    top: 48px;
    height: 104px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
.ldio-e63ka05htst div {
  position: absolute;
  width: 30px;
}
.ldio-e63ka05htst div:nth-child(1) {
  left: 35px;
  background: var(--primary);
  animation: ldio-e63ka05htst-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.2s;
}
.ldio-e63ka05htst div:nth-child(2) {
  left: 85px;
  background: var(--secondary);
  animation: ldio-e63ka05htst-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.1s;
}
.ldio-e63ka05htst div:nth-child(3) {
  left: 135px;
  background: var(--primary);
  animation: ldio-e63ka05htst-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: undefineds;
}

.loadingio-spinner-pulse-wjymhpuiss {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  transform: scale(0.6);
  /* background: #f1f2f3; */
}
.ldio-e63ka05htst {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-e63ka05htst div {
  box-sizing: content-box;
}
