.singleActivityPage {
  background-color: var(--light-2-grey);
  // padding-top: 30px;
  .carouselImage {
    height: 300px;
    // aspect-ratio: 16/9;
    width: 100%;
    // object-fit: fill;
    border-radius: 8px;
  }

  .activityImageHeaderContainer {
    .tourDetailsHeader {
      position: absolute;
      top: 620px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      width: 90%;
      background-color: var(--white);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding: 20px;
      border-radius: 8px;
      .shortDescription {
        margin: 10px 0px;
        font-size: var(--fs-4);
      }

      .TourPrice {
        // display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: start;
        background-color: var(--light-2-grey);
        padding: 20px;
        border-radius: 10px;
      }
    }
  }
  .tourDetailsOption {
    margin: 10px 0px 20px 0px;
    padding: 30px;
    // border: 1px solid var(--light-grey);
    background-color: var(--white);
    border-radius: 10px;
  }
  .tourDetailsParagraph {
    font-size: var(--fs-4);
    padding: 0px 0px 10px 0px;
    .paragraphBox {
      // border: 1px solid var(--light-grey);
      background-color: var(--white);
      margin: 20px 0px;
      border-radius: 10px;
      padding: 30px;
      // background-color: var(--white);
    }
  }
}
