#activity-admin-screen {
  .nav {
    .nav-link {
      color: var(--primary);
      border-radius: 0px;
      padding: 20px;
      border-left: 5px solid var(--light-2-grey);
      transition: 0.5s ease-in-out;
    }
    .nav-link.active {
      border-left: 5px solid var(--primary);
      color: var(--primary);
      background-color: var(--white);
      border-radius: 0px;
    }
  }
}
#inside-tab {
  .nav {
    // min-height: 80vh;
    .nav-link {
      color: var(--grey);
      border-left: 0px solid var(--light-2-grey);
      // border-radius: 6px;
      padding: 20px;
      // margin-bottom: 10px;
      transition: 0.3s ease-in-out;
      background-color: var(--light-2-grey);
    }
    .nav-link.active {
      // border-left: 4px solid var(--primary);
      color: var(--white);
      background-color: var(--primary);
      // border-radius: 6px;
    }
  }
}
