.TourOptionItem {
  background-color: var(--light-2-grey);
  padding: 10px 20px;
  font-size: var(--fs-5);
  margin-bottom: 10px;
  border-radius: 10px;
  .TourOptionItemPrice {
    text-align: end;
    font-size: var(--fs-4);
    font-weight: 600;
    color: var(--grey);
  }
}
