.manageTaskPage {
  min-height: 100vh;
  background-color: var(--light-2-grey);
  padding-top: 40px;
  .labelRow {
    background-color: var(--light-grey);
    color: var(--grey);
    font-weight: 600;
    border-bottom: 1px solid var(--grey);
    @media (max-width: 991.98px) {
      display: none;
    }
  }
}

.singleTaskListItem {
  background-color: var(--white);
  font-size: var(--fs-5);
  padding: 20px;
  margin-bottom: 8px;
  position: relative;
  &:hover {
    transition: ease 0.4s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .label {
    margin-top: 10px;
    margin-bottom: 2px;
    @media (min-width: 991.98px) {
      display: none;
    }
  }

  .statusItem {
    @media (max-width: 991.98px) {
      margin-top: 16px;
      top: -10px;
      right: 0px;
      background-color: var(--white);
    }
  }
}
