.footer {
  position: relative;
  background-color: var(--primary);
  //   border-top: 1px solid var(--grey);
  margin-top: 150px;
  @media (max-width: 991.98px) {
    margin-top: 400px;
  }
  .logo {
    width: 120px;
  }
  .firstRow {
    border-top: 1px solid var(--light-grey);
    padding: 60px 0px;
  }
  .footerContentContainer {
    width: 100%;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 991.98px) {
      bottom: -60px;
    }
    .secondRow {
      background-color: var(--white);
      padding: 40px;
      @media (max-width: 767.98px) {
        padding: 30px;
      }
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
      border-radius: 10px;
      border: 1px solid var(--light-grey);
      ul {
        padding: 0;
        list-style: none;
        li {
          margin: 0px 0px 10px 0px;
        }
      }
    }
  }
  .thirdRow {
    background-color: var(--primary);
    padding: 30px 0px;
    text-align: center;
    .copyRight {
      font-size: var(--fs-4);
      color: var(--white);
    }

    .socialIcons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0px;
      img {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 3px;
      }
      display: flex;
    }
  }
}
