.countryPage {
  .countryHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }

  .countryListItem {
    border: 1px solid var(--light-grey);
    margin-bottom: 14px;
    border-radius: 6px;
    // border-left: 2px solid var(--primary);
    transition: 0.3s all;
    &:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .countryImg {
      margin-right: 0px;
      border-radius: 5px 0px 0px 5px;
    }

    .itemData {
      font-size: var(--fs-4);
    }
  }
}
