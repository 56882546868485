.tourResultScreen {
  .modifySearch {
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .modifySearchMobile {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    font-size: var(--fs-5);
    padding: 16px 0px;
    margin-bottom: 10px;
    background-color: var(--light-2-grey);
    border-bottom: 1px solid var(--light-grey);
    @media (min-width: 991.98px) {
      display: none;
    }
  }
  .sideCartButton {
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .desktopTourFilters {
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .mobileFilter {
    @media (min-width: 991.98px) {
      display: none;
    }
  }
  .tourResultHeader {
    background-color: var(--light-2-grey);
    border: 1px solid var(--light-grey);
    border-radius: 6px;
    padding: 10px 20px;
    // margin-bottom: 16px;

    .thingstoSee {
      font-size: var(--fs-4);
      @media (max-width: 991.98px) {
        // display: none;
        margin: 10px 0px;
      }
    }

    @media (max-width: 991.98px) {
      background: none;
      border: 0px solid var(--light-grey);
      border-radius: 0px;
      padding: 0px;
      // margin-bottom: 16px;
    }
  }
}
