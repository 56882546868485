.citiesList {
  // padding: 20px;
  .CityListItem {
    background-color: var(--white);
    // border: 1px solid var(--light-grey);
    border-radius: 6px;
    margin: 20px 0px;
  }

  .citiesHeading {
    color: var(--grey);
    font-weight: 600;
    padding: 20px;
    background-color: var(--light-2-grey);
    border-bottom: 1px solid var(--light-grey);
  }
  .chooseCountryTab {
    // margin-top: 20px;
    // margin-left: 20px;
    height: 100vh;
    background-color: var(--light-2-grey);
  }

  .countryBox {
    background-color: var(--white);
    border-right: 1px solid var(--light-grey);
    padding: 10px;
    border-radius: 10px 0px 0px 10px;
    height: 100%;
  }

  .cityItems {
    // background-color: var(--light-2-grey);
    padding: 10px;
    .city {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      color: var(--primary);
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid var(--primary);
      background-color: var(--white);
    }
  }
}
