.cartPage {
  margin-top: 40px;

  .finalPayment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(240, 240, 240, 0.808);
    // border: 1px solid var(--primary);
    padding: 20px;
    border-radius: 5px;
  }

  .cartItemDetails {
    img {
      height: 120px;
    }
    div {
      font-size: var(--fs-5);
    }
    p {
      font-size: var(--fs-4);
    }
  }
}
