.tourResultItem {
  border: 1px solid var(--light-grey);
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 6px;
  transition: ease 0.2s;
  position: relative;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .ItemImage {
    border-radius: 5px;
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
  }

  .review {
    @media (max-width: 991.98px) {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.278);
      padding: 4px 6px;
      top: 8px;
      left: 8px;
      border-radius: 4px;
      color: var(--white);
      font-weight: bold;
      i {
        font-size: 10px;
      }
    }
  }
  .tourResultShortDetails {
    padding: 20px 10px;
    height: 100%;

    .shortDesicription {
      span {
        font-size: var(--fs-5);
      }
    }

    .importantInfoBtn {
      border-radius: 6px;
      font-size: var(--fs-4);
      display: flex;
      align-items: center;
      font-size: var(--fs-5);
      width: max-content;
    }
    .icon {
      color: var(--secondary);
      border: 1px solid var(--secondary);
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tourResultPriceCol {
    text-align: end;
    background-color: var(--light-2-grey);
    border-radius: 0px 5px 5px 0px;
    padding: 13px;
    @media (max-width: 991.98px) {
      padding: 10px 20px;
    }
    // margin: 20px 0px;
    .price {
      font-size: 18px;
      font-weight: 600;
      color: var(--primary);
    }
  }

  .TourOptionsLabelHeader {
    font-size: var(--fs-5);
    background-color: var(--primary);
    color: var(--white);
    font-weight: 500;
    padding: 6px 20px;
    border-radius: 6px;
    margin-bottom: 10px;
  }
}
