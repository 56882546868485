#checkoutPage {
  #completeBooking {
    padding-top: 100px;

    .heading {
      font-weight: 600;
      margin-bottom: 6px;
      color: var(--white);
    }
  }
  .box {
    background-color: var(--white);
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid var(--light-2-grey);
    border-radius: 10px;
  }

  .cartItem {
    font-size: var(--fs-5);

    .cartItemName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h6 {
        margin-bottom: 0;
      }
      font-size: var(--fs-4);
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--light-grey);
    }
  }

  .finalPayment {
    border: 1px solid var(--light-grey);
    font-size: var(--fs-5);
  }
}
