.SeasonList {
  .seasonHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }
  .SeasonListItem {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: var(--white);
    border: 1px solid var(--light-grey);
    border-radius: 6px;
    margin-bottom: 20px;

    .countryBox {
      .countryName {
        color: var(--primary);
        // border-left: 4px solid var(--primary);
        padding-bottom: 10px;
        font-weight: 600;
      }
      padding: 10px 16px;
      border-bottom: 1px solid var(--light-grey);
      height: 100%;
      display: flex;
      border-radius: 6px 6px 0px 0px;
      justify-content: space-between;
      align-items: center;
      background-color: var(--light-2-grey);
    }

    .countrySeasons {
      // border-left: 2px solid var(--primary);
      // border-radius: 6px;
      padding: 10px 16px;
      background-color: var(--light-2-grey);

      .seasonsItem {
        background-color: var(--white);
        margin: 10px 0px;
        padding: 10px;
        border: 1px solid var(--light-grey);
        // border-radius: 4px;
      }
    }
  }
}
