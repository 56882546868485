// filter

.bookingQueueFilter {
  .filterHeader {
    h6 {
      margin-bottom: 0;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--light-grey);
    padding: 16px;
    span {
      color: var(--secondary);
      cursor: pointer;
      font-weight: 500;
      &:hover {
        color: var(--grey);
      }
    }
  }
  .filterIntputs {
    border-radius: 10px;
    padding: 16px;
  }
}
