.dashboard {
  padding-top: 40px;
  min-height: 100vh;
  background-color: var(--light-2-grey);
  .barContainer {
    background-color: var(--white);
    // border: 1px solid var(--light-grey);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    min-height: 160px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    .progress-bar {
      background: var(--grey) !important;
      @media (max-width: 767.98px) {
        display: none;
      }
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  .labelBar {
    // background-color: var(--primary-light);
    padding: 16px 0px 0px 0px;
    // border-bottom: 1px solid var(--light-grey);
    margin-bottom: 10px;
    border-radius: 6px;
  }
  .staffListItem {
    margin: 0px 0px 20px 0px;
    border: 1px solid var(--light-grey);
    border-radius: 6px;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    transition: ease 0.3s;

    .staffDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;
      font-size: var(--fs-5);
      background-color: rgba(231, 231, 231, 0.774);
      border-bottom: 1px solid var(--light-grey);
      border-radius: 6px 6px 0px 0px;
      color: var(--grey);
    }
    .records {
      padding: 10px 16px;
      font-size: var(--fs-5);
    }
    &:hover {
      // border: 1px solid var(--light-grey);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }

  .dateFilter {
    .nav {
      .nav-tabs {
        .nav-link {
          padding: 10px !important;
        }
      }
    }
  }
}
