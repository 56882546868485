.enquiryForm {
  // min-height: 100vh;
  .passengerInputItem {
    background-color: var(--white);
    border: 1px solid var(--light-grey);
    font-size: var(--fs-5);
    padding: 10px;
    border-radius: 6px;
  }
}
