.bookingTourOptionItem {
  background-color: var(--white);
  margin-bottom: 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  //   border-bottom: 1px solid var(--light-grey);
  //   border-left: 2px solid var(--secondary);
  border-radius: 6px;

  .tourOptionDetails {
    padding: 14px 16px;

    .tourName {
      @media (max-width: 991.98px) {
        font-size: var(--fs-5);
      }
    }

    .optionShortDescription {
      @media (max-width: 991.98px) {
        font-size: var(--fs-6);
      }
    }
  }
  .price {
    background-color: var(--light-2-grey);
    font-size: var(--fs-5);
    border-radius: 0px 6px 6px 0px;
    padding: 10px;
    height: 100%;

    .priceItem {
      display: flex;
      justify-content: center;
      color: var(--grey);
    }

    .totalPrice {
      margin-top: 5px;
      display: flex;
      justify-content: center;

      color: var(--white);
      background-color: var(--secondary);
      border-radius: 6px;
      padding: 4px 10px;
    }
  }
}
