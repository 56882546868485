.enquiryListItem {
  margin-bottom: 24px;
  position: relative;

  .enquiryDetails {
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    border: 1px solid var(--light-grey);
    background-color: var(--white);
    padding: 12px 12px 0px 12px;
  }
  .textLight {
    font-size: var(--fs-5);
    font-weight: 500;
    color: rgb(146, 146, 146);
    margin-right: 4px;
  }
  .userDetails {
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    font-size: var(--fs-5);
    // margin-bottom: 6px;
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light-2-grey);
    border: 1px solid var(--light-grey);
    border-bottom: 0;
    background-color: var(--white);
    @media (max-width: 767.98px) {
      display: block;
    }
  }
  .guestName {
    font-size: var(--fs-5);
    // background-color: #e2eaff;
    // padding: 4px 10px;
    border-radius: 6px;
    font-weight: 600;
    // margin-bottom: 8px;
    // color: var(--primary);
  }
  .user {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-weight: 600;
    color: var(--grey);
  }
  .title {
    font-size: var(--fs-5);
    font-weight: bold;
    color: var(--grey);
  }
  .createdBy {
    font-size: var(--fs-5);
    font-weight: bold;
    color: var(--grey);
  }
  .createdAt {
    font-size: var(--fs-5);
    font-weight: bold;
    color: var(--grey);
  }
  .pax {
    font-size: var(--fs-6);
  }
  .destination {
    font-size: var(--fs-5);
    color: var(--grey);
    font-weight: bold;
  }
  .internalRemark {
    font-size: var(--fs-5);
    border-top: 1px solid var(--light-grey);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rejectionItem {
    border-top: 1px solid var(--light-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .deleteorEditEnquiryBtn {
    // position: absolute;
    top: 10px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: end;
    button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      font-size: var(--fs-5);
    }
    i {
      font-size: var(--fs-6);
    }
  }

  .remark {
    margin: 0px 10px;
    display: flex;
    background-color: #e2eaff;
    font-size: var(--fs-5);
    padding: 5px 20px;
    border-radius: 0px 0px 8px 8px;

    // border: 1px solid var(--light-grey);
    // border-top: 0;
  }
}

.enquiryListItemNotes {
  position: relative;
  .addIcon {
    cursor: pointer;
    height: 26px;
    width: 26px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    position: absolute;
    top: 12px;
    right: 10px;
    &:hover {
      background-color: var(--secondary);
      color: var(--white);
    }
  }
}
