.enquiryPage {
  padding: 0px 0px 50px 0px;
  background-color: var(--light-2-grey);
  .enquiryPageHeader {
    padding: 30px 0px;
    background-color: var(--white);
  }
  // .enquiryListContainer {
  //   margin: 30px 0px;
  // }

  .enquiryFilter {
    @media (max-width: 991.98px) {
      display: none;
    }
    top: 120px;
    z-index: 9;
  }
}
