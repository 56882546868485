@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--white);
  }
}

.activitySearchPage {
  min-height: 100vh;

  .frontPageCarouselImage {
    height: 70vh;
    width: 100%;
    border-bottom: 1px solid var(--white);
    @media (max-width: 767.98px) {
      height: 90vh;
    }
  }

  .searchPageCarousel {
    // animation: bgChanger 20s ease-in-out 1s infinite;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // aspect-ratio: 16/9;
    width: 100%;
    // object-fit: fill;
    // border-radius: 8px;

    // @media (max-width: 767.98px) {
    //   height: 420px;
    // }
    // @media (max-width: 575.98px) {
    //   height: 320px;
    // }
  }

  .searchPageCarousel {
    position: relative;
    .formContainer {
      // position: absolute;
      // top: 80vh;
      // left: 50%;
      // transform: translate(-50%, -50%);
      z-index: 9;
      width: 100%;
      // background-color: var(--white);
      // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding: 20px;
      border-radius: 8px;

      @media (max-width: 575.98px) {
        top: 530px;
        padding: 0px;
      }

      .activitySearchForm {
        background-color: var(--white);
        border-radius: 10px;
        padding: 20px 30px;
        margin-bottom: 20px;
        box-shadow: rgba(17, 17, 26, 0.2) 0px 0px 16px 2px;
        label {
          color: var(--grey);
          font-size: var(--fs-4);
          font-weight: 700;
        }
      }
    }
    .tagLine {
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(16, 11, 0, 0.597) 10%,
        rgba(255, 255, 255, 0) 80%
      );
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      z-index: 1;
      width: 100%;
      height: 70vh;
      text-align: start;

      @media (max-width: 767.98px) {
      }
      // color: var(--primary);
      // padding: 20px;
      @media (max-width: 767.98px) {
        height: 90vh;
        justify-content: end;
        top: 50%;
        transform: translate(0%, -50%);
        text-align: start;
        align-items: start;
        padding-top: 100px;
      }
      @media (max-width: 575.98px) {
        top: 50%;
        transform: translate(0%, -50%);
        text-align: start;
        align-items: start;
        padding-top: 100px;
      }

      .typewriter {
        width: max-content;
        // margin: 0 auto;
        flex-wrap: nowrap;
        transition: ease-in-out 2s;

        @media (max-width: 767.98px) {
          width: fit-content;
        }

        h1 {
          // background-color: rgba(255, 196, 0, 0.521);
          // padding: 20px 0px;
          line-height: 1.5;
          font-weight: 700;
          font-size: 46px;
          overflow: hidden;
          color: var(--white);
          border-right: 0.15em solid var(--white);
          white-space: nowrap;
          letter-spacing: 0.05em;
          animation: typing 3s steps(40, end), blink-caret 0.75s step-end;

          @media (max-width: 991.98px) {
            font-size: 40px;
          }

          @media (max-width: 767.98px) {
            animation: none;
            border-right: none;
            text-wrap: wrap;
            overflow: visible;
          }
          @media (max-width: 575.98px) {
            font-size: 34px;
          }
          span {
            font-weight: 800;
            color: var(--white);
            // background-color: var(--white);
            // padding: 10px;
          }
        }
      }
      h6 {
        font-weight: 500;
        color: var(--white);
        @media (max-width: 767.98px) {
          font-size: var(--fs-2);
        }
        @media (max-width: 575.98px) {
          font-size: var(--fs-3);
        }
      }
    }
  }

  // .activityFrontPageFloatBackground {
  //   .bgImage {
  //     background-image: linear-gradient(
  //         180deg,
  //         rgba(0, 0, 0, 0.62),
  //         rgba(255, 255, 255, 0)
  //       ),
  //       url("https://images.unsplash.com/photo-1517036570134-ff66fb62f1b6?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  //     height: 400px;
  //     background-repeat: no-repeat;
  //     background-position: 90% 55%;
  //     background-size: cover;
  //   }
  //   position: absolute;
  //   width: 100%;
  //   z-index: -1;
  // }

  .included {
    // background-color: var(--secondary-light);
    // padding: 60px 0px;
    // padding: 100px 0px 60px 0px;

    // margin-bottom: 150px;
    @media (max-width: 767.98px) {
      margin-bottom: 60px;
    }
    @media (max-width: 575.98px) {
      margin-bottom: 60px;
    }
    .includedItem {
      // text-align: center;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      // border: 1px solid var(--light-grey);
      border-radius: 10px;
      transition: ease 0.4s;
      // border-bottom: 4px solid var(--secondary);
      font-size: var(--fs-5);
      // border-right: 1px solid var(--light-grey);

      img {
        height: 70px;
        border-radius: 6px;
        padding: 16px;
        // background-color: var(--secondary-light);
        color: white;
        // background-color: var(--white);
        margin-bottom: 6px;
        transition: ease 0.8s;
      }

      h6 {
        // color: var(--secondary);
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .whyUs {
    padding: 40px 0px;
    // color: var(--white);
    // margin-bottom: 100px;
    // color: var(--white);

    .whyUsItem {
      // background: rgb(234, 234, 234);
      border: 1px solid var();
      padding: 25px;
      border-radius: 10px;
      // display: flex;
      // text-align: center;

      .whyImg {
        width: 80px;
        margin-bottom: 20px;
      }
    }
  }

  .intro {
    // background-image: url("../../assets/images/introBackground.png");
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.804),
        rgba(255, 255, 255, 0.411) 100%
      ),
      url("https://images.unsplash.com/photo-1421218108559-eb1ff78357f5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-repeat: no-repeat;
    background-size: cover;
    // margin: 0px 0px 100px 0px;
    padding: 80px 0px 80px 0px;
    background-position: center;
    background-color: var(--primary);
    // color: var(--white);
    p {
      font-size: var(--fs-4);
    }

    .point {
      margin: 30px 0px;
    }

    .aboutImage {
      border-radius: 10px;
      border: 4px solid var(--white);
      margin-top: 60px;
      height: 260px;
      width: 100%;
      object-fit: cover;
    }
  }

  .citySection {
    margin: 40px 0px 20px 0px;
    // @media (max-width: 767.98px) {
    //   margin: 300px 0px 50px 0px;
    // }
    // @media (max-width: 575.98px) {
    //   margin: 300px 0px 50px 0px;
    // }
    .heading {
      margin-bottom: 30px;
      text-align: start;
    }
    .cities {
      // flex-wrap: wrap;
      .cityItem {
        position: relative;
        // border-radius: 10px;
        margin: 0px 0px 60px 0px;
        border-radius: 8px;

        .cityImage {
          aspect-ratio: 1/1.2;
          // height: 200px;
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
          @media (max-width: 575.98px) {
            aspect-ratio: 1.8/1;
          }
          &:hover {
            // border-radius: 8px 60px 8px 8px;
            transition: ease-in-out 0.4s;
            transform: scale(0.95);
          }
        }
        .cityName {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--white);
          // border-bottom: 3px solid var(--secondary);
          position: absolute;
          bottom: -30px;
          left: 0px;
          border-radius: 0px 6px 0px 0px;
          // transform: translate(-50%, -50%);
          text-align: center;
          width: 60%;
          padding: 14px 10px;

          @media (max-width: 575.98px) {
            padding: 10px;
          }
          h6 {
            margin: 0;
            margin-top: 10px;
            color: var(--grey);
            font-size: var(--fs-4);
            font-weight: 600;
          }
        }

        .cityName:after {
          content: "";
          position: absolute;
          top: 0px;
          transform: skew(25deg);
          background-color: rgb(255, 255, 255);
          right: -16px;
          width: 35px;
          height: 100%;
          border-radius: 0px 8px 0px 0px;
          border-bottom: 1px solid var(--white);
        }
      }
    }
  }

  .mostSellingPackage {
    margin: 50px 0px 0px 0px;

    .packageItem {
      background-color: var(--white);
      // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      border: 1px solid var(--light-grey);
      border-radius: 6px;
      margin-bottom: 20px;
      img {
        border-radius: 6px 6px 0px 0px;
        height: 220px;
        width: 100%;
        object-fit: cover;
      }

      .content {
        position: relative;
        padding: 16px 16px 20px 16px;
      }
    }
  }

  .popularPackage {
    margin: 30px 0px 0px 0px;
    .heading {
      margin-bottom: 20px;
    }
  }

  .partnersLogo {
    // background-color: var(--primary-light);
    // border: 1px solid var(--light-grey);
    padding: 40px 0px;
    margin: 50px 0px;
    .partnerLogoItem {
      width: 120px;
    }
  }

  .quotationSection {
    padding: 80px 0px 0px 0px;
    background-color: rgb(242, 246, 255);
    overflow: hidden;
    .cardbox {
      border-radius: 20px;
    }
    .quotationImg {
      height: 380px;
      width: 100%;
      object-fit: contain;
      object-position: bottom;
      border-radius: 10px;
    }
  }
}
