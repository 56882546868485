.btn {
  border-radius: 6px;
  &:active {
    background-color: var(--light-grey) !important;
  }
  &:focus-visible {
    border: 0 !important;
  }
}
.btn-primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  // border-radius: 6px;
  &:hover {
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--primary);
  }
}
.btn-outline-primary {
  // background-color: var(--white);
  border: 1px solid var(--primary);
  // border-radius: 6px;
  color: var(--primary);
  &:hover {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
  }
}
.btn-light {
  background-color: var(--secondary);
  border: 2px solid var(--secondary);
  color: var(--white);
  // border-radius: 6px;
  &:hover {
    background-color: var(--white);
    color: var(--secondary);
    border: 2px solid var(--white);
  }
}

.btn-info {
  background-color: var(--light-grey);
  color: var(--grey);
  border: 0;
  &:hover {
    background-color: var(--grey);
    color: var(--white);
  }
}
