.rc-slider {
  .rc-slider-rail {
    height: 3px;
  }
  .rc-slider-track {
    height: 3px;
    background-color: var(--secondary);
  }
  .rc-slider-handle {
    height: 18px;
    width: 18px;
    background-color: var(--white);
    opacity: 1;
    margin-top: -7px;
    border: 3px solid var(--secondary);
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: var(--secondary);
    box-shadow: 0 0 0 3px var(--light-grey);
  }
  .rc-slider-handle:active {
    border-color: var(--secondary);
    box-shadow: 0 0 5px var(--secondary);
    cursor: grabbing;
  }
  .rc-slider-handle:hover {
    border-color: var(--light-grey);
  }
  .rc-slider-handle:focus-visible {
    border-color: var(--secondary);
    box-shadow: 0 0 0 3px var(--secondary);
  }
}
