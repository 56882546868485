.paginationButtonContainer {
  button:disabled {
    color: var(--grey);
    border-color: var(--grey);
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: small;
  }
}
