.tourBookingQueuePage {
  .queueListHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6 {
      margin-bottom: 0;
      @media (max-width: 991.98px) {
        font-size: var(--fs-4);
      }
    }
    background-color: var(--white);
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  min-height: 100vh;
  background-color: var(--light-2-grey);
  .bookingQueue {
    padding-bottom: 16px;
    margin-bottom: 10px;
    .cartNameRow {
      padding: 8px 16px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      // border-bottom: 1px solid var(--light-grey);
      // border-left: 1px solid var(--light-grey);
      background-color: var(--white);
      margin-bottom: 1px;
      border-radius: 6px;

      .cartName {
        color: var(--primary);
        font-size: var(--fs-4);
        font-weight: 600;
      }
    }
  }

  .queueFilterDesktop {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: var(--white);
    border-radius: 10px;
    @media (max-width: 991.98px) {
      display: none;
    }
  }

  .bookingQueueFilterMobile {
    @media (min-width: 991.98px) {
      display: none;
    }
  }
}
