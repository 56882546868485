// float button
.floatingButton {
  @media (min-width: 991.98px) {
    display: none;
  }
  position: fixed;
  bottom: 50px;
  left: 30px;
  // @media (max-width: 991.98px) {
  //   bottom: 200px;
  // }
  // @media (max-width: 767.98px) {
  //   bottom: 100px;
  // }
  .button {
    background-color: var(--primary);
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
