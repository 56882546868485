.loginPage {
  // background-color: var(--light-2-grey);

  background: linear-gradient(
      97deg,
      rgba(255, 255, 255, 0.636) 0%,
      rgba(0, 0, 0, 0.605) 50%
    ),
    url("https://images.unsplash.com/photo-1510414842594-a61c69b5ae57?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loginSideImage {
    width: 450px;
    border-radius: 10px 0px 0px 10px;
    height: 600px;
    object-fit: cover;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  .loginFormContainer {
    width: 450px;
    background-color: var(--white);
    padding: 30px 20px;
    border-radius: 0px 10px 10px 0px;

    @media (max-width: 767.98px) {
      border-radius: 10px;
    }
  }
}
