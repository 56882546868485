.cartItem {
  background-color: var(--light-2-grey);
  padding: 14px;
  // border: 1px solid var(--light-grey);
  border-radius: 6px;
  // margin-bottom: 20px;
  position: relative;
  .deleteItemBtn {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: var(--white);
    border: 1px solid var(--secondary);
    font-size: var(--fs-6);
    border-radius: 20px;
    padding: 4px 8px;
    color: var(--secondary);
    z-index: 2;
    cursor: pointer;
    &:hover {
      color: var(--white);
      background-color: var(--secondary);
    }
  }
  .comboHeading {
    // position: absolute;
    // top: -8px;
    // left: 50%;
    // transform: translate(-50%, 0);
    z-index: 1;
    color: var(--grey);
    background-color: var(--light-grey);
    border-radius: 6px 6px 0px 0px;
    font-size: var(--fs-6);
    font-weight: 600;
    padding: 2px 12px 2px 12px;
  }
  .comboWrapper {
    border-radius: 0px 0px 6px 6px;
    padding: 16px 0px 0px 0px;
  }
  .image {
    min-height: 150px;
    // max-height: 90px;
    width: 70px;
    object-fit: cover;
    border-radius: 6px;
  }
  .tourName {
    font-size: var(--fs-6);
    font-weight: 600;
    width: fit-content;
  }

  .transferType {
    font-size: var(--fs-6);
    color: var(--secondary);
    border-radius: 4px;
    margin: 4px 0px;
  }
  .travelDate {
    background-color: var(--secondary);
    color: var(--white);
    padding: 1px 6px;
    margin-top: 5px;
    border-radius: 4px;
    width: max-content;
  }
  .tourOptionName {
    line-height: 1.6;
  }
}

#resultPageKartContainer {
  .image {
    min-height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 6px;
  }
}
