.tourQuotationPage {
  background-color: var(--light-2-grey);

  .quotationListItem {
    margin-bottom: 20px;
    border-radius: 8px;

    .quotationNameRow {
      padding: 10px 20px;
      //   border: 1px solid var(--light-grey);
      background-color: var(--white);
      margin-bottom: 1px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-left: 3px solid var(--secondary);
    }

    .quotationTourOption {
      margin: 1px 0px;
      border-left: 3px solid var(--light-grey);
      // padding: 20px;
      background-color: var(--white);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      position: relative;

      .comboQuotationItemHeading {
        position: absolute;
        top: 18px;
        // left: 0;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: var(--light-grey);
        // margin-bottom: 10px;
        padding: 2px 10px;
        font-size: var(--fs-5);
        font-weight: 600;
        // color: var(--primary);
        text-align: center;
        border-radius: 10px 10px 0px 0px;
      }

      .comboMiddleBorder {
        border-bottom: 2px dashed var(--light-grey);
        margin: 10px 200px;
      }

      .image {
        aspect-ratio: 16/9;
        object-fit: cover;
        height: 100%;
        width: 100%;
        margin-right: 20px;
        border-radius: 6px;
      }
      .price {
        font-size: var(--fs-5);
        background-color: var(--light-2-grey);
        padding: 10px;
        border-radius: 10px;
        .priceItem {
          display: flex;
          justify-content: center;
        }
        .totalPrice {
          font-weight: 600;
          background-color: var(--secondary);
          color: var(--white);
          margin-top: 4px;
          border-radius: 4px;
          padding: 4px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
