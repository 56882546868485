.aboutUs {
  .sectionDivider {
    display: flex;
    align-items: center;
    margin: 20px 0 40px 0;
    min-height: 2px;
    min-width: 100%;
    background: linear-gradient(
      270deg,
      hsla(0, 0%, 0%, 0.431) -0.13%,
      hsla(0, 0%, 88%, 0.05) 0,
      #e0e0e0 49.17%,
      hsla(0, 0%, 88%, 0.05)
    );
  }
  min-height: 100vh;

  .aboutUsTopImage {
    background-image: linear-gradient(
        360deg,
        rgba(0, 8, 49, 0.813),
        rgba(255, 255, 255, 0)
      ),
      url("../../assets/images/aboutUsBackground.png");
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      color: var(--white);
    }
  }

  .officeImages {
    .overlayGradient {
      display: flex;
      align-items: center;
      height: 50vh;

      background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.22) 0%,
          rgba(0, 0, 0, 0.421) 100%
        ),
        url("https://images.unsplash.com/photo-1725733802754-c2a87bda47b2?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .aboutHeading {
        text-align: center;
        width: 70%;
        // padding: 50px;
        // background-color: rgba(255, 255, 255, 0.835);

        h1 {
          font-family: "Seaweed Script", cursive;
          font-size: 100px;
          color: var(--white);
          @media (max-width: 767.98px) {
            font-size: 80px;
          }
        }
        h5 {
          font-size: 28px;
          font-weight: 500;
          color: var(--white);
          @media (max-width: 767.98px) {
            font-size: 20px;
          }
        }
      }
    }
  }

  .description {
    background-color: rgb(243, 243, 243);
    padding: 80px 40px;
    text-align: center;
  }

  .points {
    padding: 50px 0px;
    text-align: center;

    .point {
      padding: 20px;
      img {
        width: 80px;
        margin-bottom: 30px;
      }
    }
  }

  .modules {
    // background-color: var(--light-2-grey);
    // margin: 200px 0px;
    padding: 50px 0px 50px 0px;
    // margin: 50px 0px 0px 0px;
    .moduleItem {
      // border: 1px solid var(--light-grey);
      // border-radius: 10px;
      padding: 30px 0px;
      text-align: center;
      transition: ease-in 0.18s;
      border-radius: 10px;
      h6 {
        // color: var(--primary);
        font-weight: 700;
      }
      .icon {
        width: 40px;
        margin-bottom: 20px;
      }

      &:hover {
        background-color: var(--light-2-grey);
      }
    }
  }

  .certified {
    // background-color: var(--light-2-grey);
    display: flex;
    align-items: center;
    // margin: 50px 0px;
    padding: 20px 0px;
    @media (max-width: 767.98px) {
      margin: 30px 0px;
    }
    .certifiedItem {
      padding: 20px;
      text-align: center;
      background-color: var(--white);
      background-color: rgb(242, 242, 242);
      // border: 1px solid var(--light-grey);
      border-radius: 5px;
      @media (max-width: 767.98px) {
        margin-bottom: 20px;
      }
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
}
