@keyframes bgChanger {
  0% {
    background-image: linear-gradient(
        360deg,
        rgba(0, 8, 49, 0.848),
        rgba(255, 255, 255, 0)
      ),
      url("../../assets/images/sectionBackground/slide1.png");
  }
  20% {
    background-image: linear-gradient(
        360deg,
        rgba(0, 8, 49, 0.848),
        rgba(255, 255, 255, 0)
      ),
      url("../../assets/images/sectionBackground/slide2.jpg");
  }
  40% {
    background-image: linear-gradient(
        360deg,
        rgba(0, 8, 49, 0.848),
        rgba(255, 255, 255, 0)
      ),
      url("../../assets/images/sectionBackground/slide3.jpg");
  }
  60% {
    background-image: linear-gradient(
        360deg,
        rgba(0, 8, 49, 0.848),
        rgba(255, 255, 255, 0)
      ),
      url("../../assets/images/sectionBackground/slide4.jpg");
  }
  80% {
    background-image: linear-gradient(
        360deg,
        rgba(0, 8, 49, 0.848),
        rgba(255, 255, 255, 0)
      ),
      url("../../assets/images/sectionBackground/slide5.png");
  }
  100% {
    background-image: linear-gradient(
        360deg,
        rgba(0, 8, 49, 0.848),
        rgba(255, 255, 255, 0)
      ),
      url("../../assets/images/sectionBackground/slide1.png");
  }
}

.dubaiLandingPage {
  .swithButton {
    background-color: var(--white);
    padding: 4px 20px;
    border-radius: 10px;
    position: fixed;
    top: 100px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .heroSection {
    animation: bgChanger 20s ease-in-out infinite;
    height: 85vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: end;
    justify-content: center;
    background-position: center;
    position: relative;
    .contactButton {
      position: absolute;
      left: 50%;
      bottom: -30px;
      transform: translate(-50%, 0);
      background-color: var(--secondary);
      padding: 20px 30px;
      border-radius: 50px;
      color: var(--white);
      font-weight: 600;
      @media (max-width: 575.98px) {
        width: 90%;
      }
    }

    .packagePrice {
      background: #ffffff3e;
      // backdrop-filter: blur(10px);
      // -webkit-backdrop-filter: blur(10px);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin-bottom: 80px;
      color: var(--white);
      padding: 30px;
      text-align: end;
      // border-right: 2px solid var(--white);
      h1 {
        font-weight: 600;
      }
      @media (max-width: 767.98px) {
        background: #ffffff00;
        text-align: start;
        padding: 0px;
      }
    }

    .heading {
      // text-align: center;
      color: var(--white);
      margin-bottom: 80px;
      color: rgb(255, 255, 255);
      span {
        font-weight: 600;
        font-size: 30px;
        // font-family: "Satisfy", cursive;
        line-height: 1.4;
      }

      .subHeading {
        font-size: var(--fs-1);
        font-weight: 500;
        margin-top: 20px;
        span {
          font-size: var(--fs-1);
          font-weight: 700;
          color: var(--secondary);
        }
      }

      .cityName {
        font-family: "arabiFont";
        font-size: 200px;
        font-weight: 500;
        line-height: 1;
        // background: url("https://images.unsplash.com/photo-1652707228067-25672fa0b082?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
        //   0 0 / cover no-repeat;
        color: var(--white);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        @media (max-width: 767.98px) {
          font-size: 140px;
        }
      }
    }
  }

  .intro {
    margin: 60px 0px;
    padding: 60px 0px;

    .point {
      margin: 30px 0px;
    }
  }

  .sectionHeading {
    margin-bottom: 40px;
  }

  .spanBorder {
    border-bottom: 1px solid var(--secondary);
    width: 80px;
  }

  .activities {
    margin: 0px 0px 0px 0px;
    padding: 100px 0px;
    background-image: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.681)
      ),
      url("../../assets/images/sectionBackground/activityBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .activityItem {
      position: relative;
      margin-bottom: 20px;
      .activityImage {
        height: 500px;
        width: 100%;
        object-fit: cover;
      }
      .activityDetails {
        width: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        padding: 180px 30px 30px 30px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        h5 {
          color: var(--white);
        }

        p {
          margin-top: 10px;
          color: var(--white);
          font-size: var(--fs-4);
        }
      }
    }
  }

  .activityImages {
    margin: 60px 0px;
    padding: 60px 0px;
    background-color: var(--secondary-light);

    .activityImage {
      height: 300px;
      width: 100%;
      object-fit: cover;
      border: 10px solid var(--light-grey);
      border-radius: 120px;
    }
  }

  .hotel {
    margin: 0px 0px 60px 0px;
    padding: 100px 0px;
    // background: linear-gradient(
    //   0deg,
    //   rgba(0, 0, 0, 1) 0%,
    //   rgba(255, 255, 255, 0) 100%
    // );

    background-image: linear-gradient(
        270deg,
        rgba(0, 47, 64, 0.528) 0%,
        rgba(0, 3, 55, 0.764) 100%
      ),
      url("../../assets/images/sectionBackground/hotelBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .hotelItem {
      position: relative;
      margin-bottom: 30px;
      .inclusionImage {
        width: 100%;
        height: 250px;
        object-fit: cover;
        @media (max-width: 991.98px) {
          height: 350px;
        }
        @media (max-width: 767.98px) {
          height: 350px;
        }
      }

      .inclusionDetails {
        // position: absolute;
        // background-color: #000000a9;
        // background: linear-gradient(
        //   0deg,
        //   rgba(0, 0, 0, 1) 0%,
        //   rgba(255, 255, 255, 0) 100%
        // );
        background-color: var(--white);
        // border: 1px solid var(--light-grey);
        padding: 20px;
        bottom: 0;
        width: 100%;
        font-size: var(--fs-1);
        font-weight: 500;
        p {
          font-size: var(--fs-4);
          color: var(--grey);
          font-weight: 400;
        }
      }
      // &:hover {
      //   .inclusionDetails {
      //     align-items: center;
      //     display: flex;
      //     justify-content: center;
      //   }
      // }
    }
    .planPoint {
      margin: 5px 0px;
      padding: 15px 0px;
      display: flex;
      align-items: center;

      .icon {
        background-color: var(--white);
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        i {
          color: var(--secondary);
        }
      }

      span {
        font-size: var(--fs-4);
        color: var(--grey);
      }
    }
  }

  .addOnActivity {
    padding: 120px 0px;
    background: linear-gradient(
      270deg,
      rgba(180, 235, 255, 0.528) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    .addOnActivityItem {
      position: relative;
      img {
        // height: 360px;
        width: 100%;
        object-fit: cover;
      }
      .activityName {
        // border-top: 5px solid var(--secondary);
        // border-radius: 10px;

        // position: absolute;
        width: 100%;
        // height: 100%;
        font-variant: var();

        bottom: 0;
        left: 0;
        display: flex;
        align-items: end;
        padding: 40px;
        font-size: 26px;
        font-weight: 600;
        p {
          font-size: var(--fs-4);
        }
        ul {
          list-style: none;
          padding: 0;
          li {
            padding: 6px 0px;
            display: flex;
            // align-items: center;
            i {
              color: var(--secondary);
              font-size: var(--fs-2);
              margin-top: 3px;
              // border: 1px solid var(--secondary);
              // width: 30px;
              // height: 30px;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // border-radius: 30px;
            }
          }
        }
      }
    }
  }

  .packageInclude {
    padding: 120px 0px;
    background: linear-gradient(
      270deg,
      rgba(180, 235, 255, 0.528) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    .includedImage {
      margin-bottom: 24px;
      img {
        width: 100%;
        aspect-ratio: 1.5/1;
        object-fit: cover;
        // height: 100%;
      }
    }
    .inclusionPoint {
      display: flex;
      margin-bottom: 30px;

      @media (max-width: 1199.98px) {
        font-size: var(--fs-5);
      }
      @media (max-width: 991.98px) {
        font-size: var(--fs-6);
        padding: 20px;
      }
      @media (max-width: 767.98px) {
        font-size: var(--fs-6);
        padding: 10px;
      }

      .icon {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        padding: 15px;
      }
      .iconHotel {
        background-color: var(--secondary-light);
      }
      .activity {
        background-color: rgb(198, 224, 255);
      }
      .iconTransfer {
        background-color: rgb(237, 255, 207);
      }
      .iconVisa {
        background-color: rgb(255, 203, 203);
      }
      h6 {
        color: var(--grey);
        margin: 0;
        margin-top: 14px;
        line-height: 1.4;
        font-size: var(--fs-3);
      }
    }
  }

  .exploreMore {
    padding: 120px 0px;

    .moreExploreItem {
      position: relative;
      img {
        border-radius: 10px;
        height: 360px;
        width: 100%;
        object-fit: cover;
      }
      .activityName {
        border-bottom: 5px solid var(--secondary);
        border-radius: 10px;

        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(30deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        bottom: 0;
        left: 0;
        display: flex;
        align-items: end;
        padding: 40px;
        font-size: 30px;
        font-weight: 600;
        color: var(--white);
      }
    }
  }

  .planDubai {
    margin: 60px 0px;
    background-color: var(--secondary-light);
    padding: 100px 0px;
    h2 {
      font-size: 40px;
      color: var(--secondary);
      font-weight: 700;
      margin-bottom: 20px;
    }
    .planPoint {
      margin: 5px 0px;
      padding: 15px 0px;
      display: flex;

      .icon {
        background-color: var(--white);
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        i {
          color: var(--secondary);
        }
      }

      span {
        font-size: var(--fs-4);
        color: var(--grey);
      }
    }
  }

  .tours {
    margin: 0px 0px 0px 0px;
    padding: 100px 0px;

    .tourItem {
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      font-size: var(--fs-4);
      border: 1px solid var(--light-grey);
      .tourImage {
        height: 200px;
        object-fit: cover;
        width: 100%;
      }
      .tourItemContent {
        padding: 20px;
      }
      .button {
        border-top: 1px solid var(--light-grey);
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 40px;
        transition: ease 0.4s;
        // color: var(--primary);
      }
      &:hover {
        .button {
          padding-right: 20px;
        }
      }
    }
  }
}
