.form-check-input {
  height: 16px;
  width: 16px;
  &:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &:focus {
    border-color: #d0d0d0;
    box-shadow: 0 0 0 0.25rem rgba(118, 118, 118, 0.25);
  }
}
